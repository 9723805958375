import * as React from "react";
import Layout from "../../components/Layout";
import Checkerboard from "../../ui-blocks/Checkerboard";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";
import empty_png from "../../images/logo/empty.png";
import art_direction_desktop_jpg from "../../images/tvc-endframes/art-direction_desktop.jpg";
import editing_notes_desktop_jpg from "../../images/tvc-endframes/editing-notes_desktop.jpg";
import using_gray_desktop_jpg from "../../images/tvc-endframes/using-gray_desktop.jpg";

const TvcEndframe = (props) => {
  return (
    <Layout>
      <div
        className="page-header video-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "#000000" }}
      >
        <div className="background" style={{ backgroundColor: "#000000" }}>
          <picture>
            <source
              media="(max-width:767px)"
              srcSet="/brandguidelines/imgix/typography/section-header/typog_header_mobile.jpg"
            />
            <source
              media="(min-width:768px) and (max-width:1024px)"
              srcSet="/brandguidelines/imgix/typography/section-header/typog_header_tablet.jpg"
            />
            <source
              media="(min-width:1900px)"
              srcSet="/brandguidelines/imgix/typography/section-header/typog_header.jpg"
            />
            <img
              alt=""
              src="/brandguidelines/imgix/typography/section-header/typog_header_desktop.jpg"
              alt="Type related words on black background"
            />
          </picture>
        </div>

        <div className="video-wrap" style={{ height: "650px" }}>
          <div className="video">
            <div className="video-poster ">
              <picture>
                <source
                  media="(max-width:767px)"
                  srcSet="/brandguidelines/images/video-poster/TOYOTA_TYPE_FINAL_040218_mobile.png"
                />
                <source
                  media="(min-width:768px) and (max-width:1024px)"
                  srcSet="/brandguidelines/images/video-poster/TOYOTA_TYPE_FINAL_040218_desktop.png"
                />
                <source
                  media="(min-width:1900px)"
                  srcSet="/brandguidelines/images/video-poster/TOYOTA_TYPE_FINAL_040218_desktop.png"
                />
                <img
                  alt=""
                  src="/brandguidelines/images/video-poster/TOYOTA_TYPE_FINAL_040218_desktop.png"
                  alt="Hands writing on paper with type samples"
                />
              </picture>
            </div>
            <div style={{ width: "100%", height: "100%" }}>
              <video
                preload="auto"
                src="blob:https://www.toyota.com/2f6992e0-ab5c-4619-8897-96378bd118f9"
                style={{ width: "100%", height: "100%" }}
              ></video>
            </div>

            <button
              className="play-btn "
              style={{ borderColor: "rgb(255, 255, 255)" }}
            >
              <div style={{ borderColor: "rgb(255, 255, 255)" }}></div>
            </button>
          </div>
        </div>
      </div>

      <section className="component Section" id="overview">
        <div className="section-header darker-background" data-tag="absent">
          <div className="container">
            <h2 className="ui header">TVC Endframe</h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              To ensure strong, recognisable branding across all video and audio
              asests, it's important to have a consistent end tag.
            </div>

            <div className="copy spaced-p">
              We will always end on our staging platform on both broadcast and
              online assets. There is a end frame for videos over 30 seconds and
              a shorter version for videos under 30 seconds.
            </div>

            <div className="copy spaced-p">
              The same applies to radio, whereby we need to end on a consistent
              and distinctive Toyota audio mnemonic.
            </div>
          </div>
        </div>

        {/* TODO: T-image block  */}

        {/* <div className="image" style={{background:'#fafafa'}} data-compid="endframe-1">
            <div className="full-width">
                <div className="image">
                    <picture>
                        <source media="(max-width:767px)" srcSet="/brandguidelines/imgix/color/color-use/colors_use_red_mobile.jpg"/>
                        <source media="(min-width:768px) and (max-width:1024px)" srcSet="/brandguidelines/imgix/color/color-use/colors_use_red_tablet.jpg"/>
                        <source media="(min-width:1900px)" srcSet="/brandguidelines/imgix/color/color-use/colors_use_red.jpg"/>
                        <img alt="" src="/brandguidelines/imgix/color/color-use/colors_use_red_desktop.jpg" alt="National Clearance Event billboard"/>
                    </picture>
                </div>
            </div>
        </div> */}

        {/* begin T-image block */}
        <div style={{ background: "#fafafa", paddingBottom: "80px" }}>
          <div className="image">
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <img
                  alt=""
                  src={{ empty_png }}
                  style={{
                    height: "500px",
                    width: "1140px",
                    margin: "0 auto 0 auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end T-image block */}
      </section>

      <section className="component Section" id="endframe">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">
              <em>Let's Go places</em> end frame
            </h2>
          </div>
        </div>

        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <h3>End frame</h3>
            <div className="copy">
              The <em>Let's Go Places</em> end frame is preferred. Please use
              the design and animation provided for your format and media.
            </div>

            <h3>Art direction</h3>
            <div className="copy">
              The last shot of the video should be art directed with the end
              frame transition in mind - wide shots are preferred if possible.
              Avoid close ups on faces or important objects in the center of
              frame, as we do not cover them with the end frame transition.
            </div>

            <h3>Editing notes</h3>
            <div className="copy spaced-p">
              Make sure there are no cuts or important action at the same time
              as the transition. Any actions relevant to the story should
              conclude before the <em>Let's Go Places</em> logo transition.
            </div>

            <div
              className="image"
              style={{ paddingTop: "80px", paddingBottom: "80px" }}
            >
              <div className="full-width">
                <div
                  className="image"
                  style={{ display: "flex", height: "500px" }}
                >
                  <img
                    alt=""
                    src={{ empty_png }}
                    style={{
                      height: "500px",
                      width: "1140px",
                      margin: "0 auto 0 auto",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="component Section" id="brand-campaign-end-frame">
        <div className="section-header lighter-background">
          <div className="container">
            <h2 className="ui header">Brand Campaign end frame</h2>
          </div>
        </div>
        <div className="blog-content lighter-background">
          <div className="container onecolumn" id="">
            <div className="copy spaced-p">
              Below are some additional guidelines for brand campaigns that have
              their own lock up or end line.
            </div>
          </div>
        </div>

        {/* TODO: Checkerboard tiles  must be square when scaled  */}

        <Checkerboard
          rows={[
            {
              img_src: art_direction_desktop_jpg,
              img_style: { width: "100%", height: "100%" },
              title: "Legibility",
              copy: "Where there is a campaigns lock up or line, please ensure that is it clearly legible.",
            },
            {
              img_src: editing_notes_desktop_jpg,
              img_style: { width: "100%", height: "100%" },
              title: "Editing notes",
              copy: "Ensure the lock up or line fades out before the <em>Let&#39;s Go Places</em> logo transitions on. There should always be at least one clean frame between the graphics and the logo animation.",
            },
            {
              img_src: using_gray_desktop_jpg,
              img_style: { width: "100%", height: "100%" },
              title: "CTA",
              copy: "A simple call to action can be included in the final end frame below the Let&#39;s Go Places logo. Please refer to the example and guidelines.",
            },
          ]}
        />
      </section>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Colours"
        previousUrl="/brandguidelines/colours"
        nextText="Next"
        nextHeader="Messaging Platform"
        nextUrl="/brandguidelines/messaging-platform"
      />
    </Layout>
  );
};
export default TvcEndframe;
